export const treats =
[
  {
    itemName: 'Sugar Cookies',
    prices: [{quantity: '12 decorated cookies', price: 'starting at $50'}],
    notes: 'Cookie Size: 3+"',
    image: 'cookies/babyshower13'
  },
  {
    itemName: 'Cupcakes',
    prices: [{quantity: '12 cupcakes', price: 'starting at $42'}],
    image: 'other/cupcakes17'
  },
  {
    itemName: 'Cake Pops',
    prices: [{quantity: '12 pops', price: 'starting at $32'}],
    image: "other/cakepop13"
  },
  {
    itemName: 'Cocoa bombs',
    prices: [{quantity: '6 pack', price: 'starting at $'}],
    image: 'other/cocoabombs4'
  },
  {
    itemName: 'Breakable Hearts',
    prices: [{quantity: '6" Heart', price: 'starting at $40'}],
    image: 'other/treatbox8'
  },
  {
    itemName: 'Chocolate Covered Oreos',
    prices: [{quantity: '12 oreos', price: 'starting at $33'}],
    image: 'other/oreos1'
  },
  {
    itemName: 'Butter Cookies',
    prices: [{quantity: '6', price: '$20'}],
    notes: 'Cookie Size: 4"',
    image: 'cookies/buttercookies1'
  },
];

export const cakes =
  [
    {
      itemName: 'Trinidad Black/ Fruit Cake (Rum Cake)',
      prices: [{quantity: '8"', price: '$30'}, {quantity: '10"', price: '$40'}],
      image: 'cakes/blackcake2'
    },
    {
      itemName: 'Trinidad Sponge Cake',
      prices: [{quantity: '10"', price: '$35'}],
      image: 'cakes/sponge1'
    },
    {
      itemName: 'Caramel Custard (Flan)',
      prices: [{quantity: '8"', price: '$30'}],
      image: 'cakes/flan2'
    },
    {
      itemName: 'Tiramisu',
      prices: [{quantity: '8"', price: '$50'}],
      image: 'cakes/tiramisu3'
    },
    {
      itemName: 'Pone',
      prices: [ {quantity: '4 x 8', price: '$15'}, {quantity: '9 x 5', price: '$25'}, {quantity: '11 x 9', price: '$75'}],
      image: 'sweets/pone1'
    }
  ];


export const sweets =
  [
    {
      itemName: 'Gulab Jamoon (Fat Kurma)',
      prices: [{quantity: '15 pieces', price: '$25'}],
      image: 'sweets/gulabjamoon2'
    },
    {
      itemName: 'Ladoo',
      prices: [{quantity: '15 pieces', price: '$25'}],
      image: 'sweets/ladoo6'
    },
    {
      itemName: 'Coconut Barfi',
      prices: [{quantity: '15 pieces', price: '$30'}],
      image: 'sweets/coconutbarfi1'
    },
    {
      itemName: 'Barfi',
      prices: [{quantity: '15 pieces', price: '$30'}],
      image: 'sweets/barfi1'
    },
    {
      itemName: 'Kurma',
      prices: [{quantity: '1 lb', price: '$13'}],
      image: 'sweets/kurma1'
    },
    {
      itemName: 'Roat',
      prices: [{quantity: '10 pieces', price: '$45'}],
      image: 'sweets/roat1'
    },
    {
      itemName: 'Parsad',
      prices: [{quantity: '1 lb', price: '$25'}],
      image: 'sweets/parsad1'
    },
    {
      itemName: 'Fudge',
      prices: [{quantity: '15 pieces', price: '$30'}],
      image: 'sweets/fudge1'
    },
    {
      itemName: 'Rasmalai',
      prices: [{quantity: 'xs', price: '$25'}, {quantity: 'sm (11 x 9)', price: '$50'}, {quantity: 'lg (19 x 11)', price: '$125'}],
      image: 'sweets/rasmali1'
    },
    {
      itemName: 'Rasgulla',
      prices: [{quantity: 'xs', price: '$25'}, {quantity: 'sm (11 x 9)', price: '$50'}, {quantity: 'lg (19 x 11)', price: '$125'}],
      image: 'sweets/rasgulla1'
    },
    {
      itemName: 'Sahari & Lapse',
      prices: [{quantity: '10 Sahari (5 pairs) & 1 lb Lapse', price: '$40'}],
      image: 'sweets/lapsi1'
    },
  ];

export const eggless =
  [
    {
      itemName: 'Eggless Sugar Cookies',
      prices: [{quantity: '12 decorated cookies', price: 'starting at $55'}],
      notes: 'Cookie Size: 3+"',
      image: 'cookies/cocomelon3'
    },
    {
      itemName: 'Eggless Cake Pops',
      prices: [{quantity: '12 pops', price: 'starting at $36'}],
      image: 'other/cakepop16'
    },
    {
      itemName: 'Eggless Cupcakes',
      prices: [{quantity: '12 cupcakes', price: 'starting at $45'}],
      image: 'other/cupcakes13'
    },
    {
      itemName: 'Eggless Caramel Custard (Flan)',
      prices: [{quantity: '8"', price: '$35'}],
      image: 'cakes/flan1'
    },
    {
      itemName: 'Eggless Cake',
      prices: [{quantity: '8"', price: '$35'}],
      image: 'cakes/sponge5'
    },
    {
      itemName: 'Eggless Trinidad Sponge Cake',
      prices: [{quantity: '10"', price: '$40'}],
      image: 'cakes/sponge3'
    },
  ];

export const breads =
  [
    {
      itemName: 'Currants Roll',
      prices: [{quantity: '4 pieces', price: '$12'}],
      image: 'sweets/currantsroll1'
    },
    {
      itemName: 'Sweet Bread',
      prices: [{quantity: '4 x 8', price: '$15'}, {quantity: '5 x 11', price: '$25'}],
      image: 'sweets/sweetbread1'
    },
  ];
