import React from 'react';
import Item from './item';
import { Grid } from '@material-ui/core';
import { LinedHeader } from './styledComponents';
import styled from "styled-components";
import { Link } from 'gatsby'


const StyledLink = styled(Link)`
  color: black;
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  margin: 0 10px;
`

const ItemSection = (props) => {
  return (
    <Grid container item xs={12} key={`itemsection-${props.category}`} >
      <Grid item xs={12}>
        <LinedHeader>
          {props.category}
        </LinedHeader>
      </Grid>
      <Grid className="item-section" container item xs={12} justify="center">
        {props.items.map(item => {
          return (
            <Item
              name={item.itemName}
              prices={item.prices}
              image={item.image}
              notes={item.notes}
            />
          )
        })}
      </Grid>
    </Grid>
  );
};

export default ItemSection;
