import React from 'react';
import styled from "styled-components";
import { Grid } from '@material-ui/core';

const Image = styled.img`
  height: 150px;
  width: 190px;
  align-self: center;
  object-fit: cover;
`

const Item = (props) => {
  return (
    <Grid className="item-container" container item md={4} sm={6}>
      <Grid className="image-container" item >
        <Image src={require(`../images/${props.image}.jpg`).default} alt={props.itemName} className="item-img" />
      </Grid>
      <Grid container item className="inner-container">
        <Grid item xs={12} style={styles.itemName}>{props.name}</Grid>
          {props.notes && <Grid item xs={12}>{props.notes}</Grid>}
        <Grid container>
          {props.prices.map((price) => {
            return (
              <Grid item xs={12} style={styles.price}>{price.quantity} &mdash; {price.price}</Grid>
            )
          })}
        </Grid>
      </Grid>

    </Grid>
  );
};

const styles = {
  itemName: {
    fontWeight: "bold",
    lineHeight: '28px',
    fontSize: '26px'
  },
  price: {
    fontSize: '16px'
  }
};

export default Item;
