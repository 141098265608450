import React from 'react';
import ItemSection from '../components/ItemSection';
import {cakes, treats, sweets, breads, eggless} from '../components/items';
import { Grid, makeStyles } from '@material-ui/core';
import Header from "../components/header"
import Footer from '../components/footer';

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: '0px 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px'
    }
  },
}));

const Menu = () => {
  const classes = useStyles();

  return (
    <div>
      <Header title="Menu" />
      <Grid container className={classes.menu}>
        <ItemSection category='Treats' items={treats}/>
        <ItemSection category='Cakes' items={cakes}/>
        {/* <Grid container item xs={12} justify="space-between">
            <GridContainer item md={3} container justify="center">
              <GridHeader item xs={12} container justify="center">Cake Flavors</GridHeader>
              <Grid item xs={6}>
                <ul>
                  <li>Vanilla</li>
                  <li>Red Velvet</li>
                  <li>Chocolate</li>
                  <li>Black Forrest</li>
                  <li>Chai</li>
                  <li>Carrot</li>
                  <li>Sponge Cake</li>
                </ul>
              </Grid>
              <Grid item xs={6}>
                <ul>
                  <li>Eggless Vanilla</li>
                  <li>Eggless Red Velvet</li>
                  <li>Eggless Chocolate</li>
                  <li>Trinidad Rum Fruit</li>
                  <li>Rum Vanilla</li>
                  <li>Lemon Vanilla</li>
                </ul>
              </Grid>
            </GridContainer>
            <GridContainer item md={3} container justify="center">
              <GridHeader item xs={12} container justify="center">Frosting Flavors</GridHeader>
              <Grid item xs={12}>
                <ul>
                  <li>Vanilla</li>
                  <li>Cream Cheese</li>
                  <li>Chocolate</li>
                  <li>Royal Icing</li>
                  <li>Fondant</li>
                </ul>
              </Grid>
            </GridContainer>
            <GridContainer item md={3} container justify="center">
              <GridHeader item xs={12} container justify="center">Fillings & Decor</GridHeader>
              <Grid item xs={12}>
                <ul>
                  <li>Vanilla Buttercream</li>
                  <li>Cream Cheese Buttercream</li>
                  <li>Chocolate Buttercream</li>
                  <li>Raspberry</li>
                  <li>Strawberry</li>
                  <li>Blueberry</li>
                </ul>
              </Grid>
            </GridContainer>
          </Grid> */}
        <ItemSection category='Pooja Sweets' items={sweets}/>
        <ItemSection category='Eggless Options' items={eggless}/>
        <ItemSection category='Breads' items={breads}/>
      </Grid>
      <Footer />
    </div>
  );
};

export default Menu;
